export enum AdminRoute {
    AIPrompts = 'ai-prompts',
    AIResults = 'ai-results',
    Ads = 'ads',
    AdsTxt = 'ads-txt',
    Advice = 'advice',
    AiPrompts = 'ai-prompts',
    AiResults = 'ai-results',
    Article = 'artikel',
    Articles = 'articles/[[...status]]',
    AuditLogs = 'audit-logs',
    Banner = 'banner',
    BlockedDomain = 'blocked-domain',
    BlockedIps = 'blocked-ips',
    Bookmakers = 'bookmakers/[[...status]]',
    Bottom = 'bottom',
    CarouselHeadlines = 'carousel-headlines',
    Comments = 'comments/[[...tab]]',
    Curation = 'curation',
    DeprecatedPaginas = 'paginas',
    Experiments = 'experiments',
    Faqs = 'faqs',
    FilteredComments = 'filtered-comments',
    Flag = 'flag',
    ForbiddenWords = 'forbidden-words',
    Gebruiker = 'gebruiker/[[...index]]',
    Hoofdpagina = 'hoofdpagina',
    Index = '/',
    Jersey = 'jersey',
    Link = 'links/[id]',
    Links = 'links',
    Liveness = 'liveness',
    Logs = 'logs',
    ManageTags = 'tags/manage',
    MatchEvents = 'match-events',
    MatchReports = 'match-reports',
    MatchTicker = 'match-ticker',
    Media = 'media',
    MediaNews = 'media/[[...newsID]]',
    MediaVideofeed = 'media/videofeed/[[...status]]',
    ModuleSchedule = 'module-schedule/[[...tab]]',
    NavItems = 'nav-items',
    NewsCategory = 'news-category',
    NotificationBar = 'notificationbar',
    OddsDossier = 'odds-dossier',
    OptaExport = 'opta-exports',
    OptaExports = 'opta-exports',
    Pages = 'pages',
    PagesEdit = 'pages/edit/[...slug]',
    PagesNew = 'pages/new',
    Poll = 'poll',
    Proposition = 'propositions/edit/[...propoistionsID]',
    PropositionCreate = 'propositions/new',
    Propositions = 'propositions',
    Redirects = 'redirects',
    Rooster = 'rooster',
    Sandbox = 'sandbox',
    Stats = 'stats',
    SubscriptionGifts = 'subscription-gifts',
    Tag = 'tags/[[...tagType]]',
    Tags = 'tags/[[...tagType]]',
    TagsDuplicate = 'tags/duplicate/[tagID]',
    TagsManage = 'tags/manage/[tagID]',
    Tier = 'tiers/edit/[...tierID]',
    TierCreate = 'tiers/new',
    TierGroups = 'tier-groups',
    Tiers = 'tiers',
    User = 'gebruiker',
    Users = 'users',
    Videofeed = 'media/videofeed/[[...status]]',
}
